import React from 'react';
import textoImagen from '../images/nc4.png';
import bgImage from '../images/bkground4.jpeg';
import rightImage from '../images/title.png';

const Section = () => {
  return (
    <div 
      style={{
        backgroundImage: `url(${bgImage})`, 
        backgroundSize: 'cover', 
        backgroundPosition: 'center', 
        display: 'flex', 
        alignItems: 'center', 
        justifyContent: 'center', // Centra ambos elementos horizontalmente
        padding: '20px',
        position: 'relative',
      }}
    >
      <img
        src={textoImagen}
        alt="Texto"
        style={{
          width: '40%', // Tamaño de la imagen izquierda
          marginRight: '50px', // Reduce el margen derecho para acercar las imágenes
          animation: 'fadeIn 2s ease-in-out', // Animación de aparición
        }}
      />
      <img

      />
    </div>
  );
}

// Añade estilos globales de animación
const styles = `
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`;

// Inyecta las animaciones en el DOM
const styleSheet = document.createElement("style");
styleSheet.type = "text/css";
styleSheet.innerText = styles;
document.head.appendChild(styleSheet);

export default Section;
