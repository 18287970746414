import React from 'react';
import twitterLogo from '../images/twitterIcon.png';
import telegramLogo from '../images/telegramIcon.png';
import title from '../images/title.png'
import './header.css'
// import chartLogo from './images/chart.png';

const Header = () => {
  return (
    <div className="header">
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '20px' }}>
      <img src={title} alt="Telegram" style={imageStyle2} />
        <div style={{ display: 'flex', gap: '10px' }}>
          <a href="https://x.com/NightCrawlerSui">
            <img src={twitterLogo} alt="Twitter" style={imageStyle} />
          </a>
          <a href="https://t.me/NightCrawlerSui">
            <img src={telegramLogo} alt="Telegram" style={imageStyle} />
          </a>
          {/* <a href="URL_DEL_ENLACE_PARA_CHART">
          <img src={chartLogo} alt="Chart" style={imageStyle} />
        </a> */}
        </div>
      </div>
    </div>
  );
}

// Estilo para reducir el tamaño de las imágenes y añadir la animación en el hover
const imageStyle = {
  width: '100px', // Reducción del tamaño de la imagen
  height: '100px',
  transition: 'transform 0.3s ease', // Agrega una transición para el efecto hover
};

// Estilo para reducir el tamaño de las imágenes y añadir la animación en el hover
const imageStyle2 = {
  width: '400px', // Reducción del tamaño de la imagen
  height: '100px',
  transition: 'transform 0.3s ease', // Agrega una transición para el efecto hover
};

// Aplica la animación al hacer hover sobre la imagen
imageStyle[':hover'] = {
  transform: 'scale(1.1) rotate(360deg)', // Aumenta el tamaño de la imagen en un 10% y la rota 360 grados al hacer hover
};

export default Header;

