import React, { useState } from 'react';
import "./aboutSection.css";
import gifImage from "../images/stolen5.png";
import bgImage from "../images/bkground.png";
import plan from "../images/plan.png"
import dexscreener from "../images/dexscreener.png"
import trending from "../images/trending.png"
import buy from "../images/buy.png"

const AboutSection = () => {
    const [textIndex, setTextIndex] = useState(0);

    const handleClick = () => {
        // Cambiar el índice del texto para mostrar el siguiente texto en el siguiente clic
        setTextIndex(prevIndex => (prevIndex + 1) % 3);
    };

    return (
        <section className="about-section" style={{backgroundImage: `url(${bgImage})`, backgroundSize: 'cover', backgroundPosition: 'center',}}>
            <div className='column'>
                <img className="title" src={plan} alt="plan"/>
                <div className="text-container">
                    <img className={`text ${textIndex === 0 ? 'show' : ''} top-left`} src={dexscreener} alt="comment"/>
                    <img className={`text ${textIndex === 1 ? 'show' : ''} top-left`} src={dexscreener} alt="comment"/>
                    <img className={`text ${textIndex === 2 ? 'show' : ''} top-left`} src={dexscreener} alt="comment"/>
                    <img className={`text ${textIndex === 1 ? 'show' : ''} top-right`} src={trending} alt="comment"/>
                    <img className={`text ${textIndex === 2 ? 'show' : ''} top-right`} src={trending} alt="comment"/>
                    <img className={`text ${textIndex === 2 ? 'show' : ''} bottom-center`} src={buy} alt="comment"/>
                    <img src={gifImage} alt="About Gif" onClick={handleClick} className="gif-image" />
                </div>
            </div>
        </section>
    );
}

export default AboutSection;
