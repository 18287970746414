import React, { useState } from 'react';
import fondo from './images/bkground.png';
import centralImage from './images/nc4.png'; // Imagen central
import leftGif from './images/thunder.gif'; // GIF izquierdo
import rightGif from './images/thunder.gif'; // GIF derecho
import smokeEffect from './images/smoke.gif'; // Imagen para el efecto de humo

const HomePage = () => {
    const [isClicked, setIsClicked] = useState(false);

    const handleClick = () => {
        setIsClicked(true);
        const audio = new Audio('./sounds/stole.mp3');
        audio.play();
        setTimeout(() => {
            window.location.href = "/home"; // Redireccionar a la siguiente página
        }, 5000); // Cambia el tiempo según la duración del audio
    };

    const overlayStyle = {
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.6)', // Color de fondo oscuro con opacidad
        zIndex: 2, // Asegúrate de que el overlay esté encima de otros elementos
        transition: 'opacity 3s ease-in-out', // Transición más lenta
        opacity: isClicked ? 1 : 0, // Opacidad 1 cuando se hace clic para oscurecer
    };

    const centralImageStyle = {
        width: '500px', // Tamaño de la imagen central
        cursor: 'pointer',
        zIndex: 3, // Asegúrate de que la imagen central esté encima de otros elementos
        position: 'absolute', // Necesario para centrar más fácilmente
        top: '50%', // Centrar verticalmente
        left: '50%', // Centrar horizontalmente
        transform: 'translate(-50%, -50%)', // Ajuste para centrar completamente
    };

    const gifStyle = {
        width: '500px', // Tamaño aumentado de los GIFs
        position: 'absolute',
        zIndex: 2, // Asegúrate de que los GIFs estén por debajo de la imagen central
    };

    const leftGifStyle = {
        ...gifStyle,
        left: '5%', // Ajuste para mayor tamaño de GIF
        top: '50%',
        transform: 'translateY(-50%)', // Centra verticalmente
    };

    const rightGifStyle = {
        ...gifStyle,
        right: '5%', // Ajuste para mayor tamaño de GIF
        top: '50%',
        transform: 'translateY(-50%)', // Centra verticalmente
    };

    const smokeStyle = {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundImage: `url(${smokeEffect})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        zIndex: 1, // Asegúrate de que el humo esté detrás de otros elementos
        opacity: isClicked ? 1 : 0,
        transition: 'opacity 2s ease-in-out', // Transición de opacidad
    };

    return (
        <div style={{ position: 'relative', height: '100vh', backgroundImage: `url(${fondo})`, backgroundSize: 'cover', backgroundPosition: 'center' }}>
            <img
                src={centralImage}
                alt="Central"
                style={centralImageStyle}
                onClick={handleClick}
            />
            <img
                src={leftGif}
                alt="Left GIF"
                style={leftGifStyle}
            />
            <img
                src={rightGif}
                alt="Right GIF"
                style={rightGifStyle}
            />
            <div style={overlayStyle}></div>
            <div style={smokeStyle}></div>
        </div>
    );
}

export default HomePage;
