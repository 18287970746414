import React from 'react';
import "./section2.css";
import logo from "../images/title.png"
import nine from "../images/memes/egghead.png"
import one from "../images/memes/Pills.png"
import two from "../images/memes/2.gif"

const Section2 = () => {
    return (
        <section className="photo-section">
            <div className="image-container">
                <img src={logo} alt='stolen' />
                <div className="inline-images">
                    <img src={one} alt='stolen' />
                    <img src={nine} alt='stolen' />
                    <img src={one} alt='stolen' />
                </div>
            </div>
        </section>
    );
}

export default Section2;
